<template>
  <section class="request--book-action">
    <div class="content--row">
      <div>{{ $t('general.startFrom') }}</div>
      <div>{{ startDate }}</div>
    </div>
    <div class="content--row">
      <div>{{ $t('general.until') }}</div>
      <div>{{ endDate }}</div>
    </div>
    <div class="content--row">
      <div>{{ $t('general.rentDuration') }}</div>
      <div>{{ duration }}</div>
    </div>
    <div class="content--row" v-if="listing && !listing.view_to_offer">
      <div>{{ $t('general.subPrice') }}</div>
      <div>{{ $n(subTotal, 'currency', 'en-SG') }}</div>
    </div>

    <div class="content--row" v-if="listing && !listing.view_to_offer">
      <div>{{ $t('general.totalPrice') }}</div>
      <div>{{ $n(totalRentPrice, 'currency', 'en-SG') }}</div>
    </div>
    <div
      class="content--row"
      v-if="listing && !listing.view_to_offer && discountPrice && chosenPromo && promoValid"
    >
      <div>Promo {{ chosenPromo.promo_code }}</div>
      <div class="discount">- {{ $n(discountPrice, 'currency', 'en-SG') }}</div>
    </div>
    <div class="total--row" v-if="listing && !listing.view_to_offer">
      <div>{{ $t('general.bookingFee') }}</div>
      <div>{{ $n(bookingFee, 'currency', 'en-SG') }}</div>
    </div>
    <button :disabled="!promoValid" class="btn btn-primary" @click="$emit('submit')">
      {{ priceChanged ? $t('general.btn.negotiate') : $t('general.btn.RTB') }}
    </button>
  </section>
</template>

<script>
export default {
  name: 'request-book-action',
  props: [
    'listing',
    'duration',
    'startDate',
    'endDate',
    'totalRentPrice',
    'bookingFee',
    'discountPrice',
    'chosenPromo',
    'subTotal',
    'promoValid',
  ],
  methods: {},
  computed: {
    newPrice() {
      return this.$store.state.v2.rentTransaction.initiate.newPrice;
    },
    priceChanged() {
      let different = false;
      if (this.listing) {
        different = this.newPrice != this.bookingFee / this.listing.booking_fee_month_amount;
      }
      return different;
    },
  },
};
</script>
