<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container request--book-container">
      <page-loading :show="loading" />
      <div class="left--col-container">
        <div class="request--book-content">
          <listing-info :listing="listing" class="mb-4"></listing-info>
          <!--          <promo-section />-->
          <client-only>
            <user-data-section ref="userDataSection" />
          </client-only>
          <!--          <agreement-section ref="agreementSection"></agreement-section>-->
          <nego-section ref="negoSection"></nego-section>
        </div>
      </div>
      <div class="right--col-container">
        <action
          v-if="(listing && listing.view_to_offer) || total_rent"
          :listing="listing"
          :duration="lamaSewa"
          :startDate="moveDateName"
          :endDate="endDateName"
          :totalRentPrice="total_rent"
          :discountPrice="discount_price"
          :chosenPromo="chosenPromo"
          :bookingFee="bookingFee"
          :promoValid="!promoWarning"
          :subTotal="harga_sewa"
          @submit="submit"
        />
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ListingInfo from '@/components/utils/listing-info';
import Action from '@/components/request-to-book/action';
import { mapState } from 'vuex';
const PageLoading = () => import('@/components/content-loading/page-loading');
// const PromoSection = () => import('@/components/request-to-book/promo-section');
const UserDataSection = () => import('@/components/request-to-book/user-data-section');
// const AgreementSection = () => import('@/components/request-to-book/agreement-section');
const NegoSection = () => import('@/components/request-to-book/nego-section');

export default {
  components: {
    DefaultLayout,
    ListingInfo,
    Action,
    PageLoading,
    // PromoSection,
    UserDataSection,
    // AgreementSection,
    NegoSection,
  },
  async fetch({ store, query, $date, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    store.dispatch('v2/rentTransaction/initiate/restoreInitialState');
    // const date = $date.parse(query.date, 'MM/dd/yyyy');
    const date = $date.fromUnixTime(query.date);
    // const dateFormatted = $date.format(date, 'dd-MM-yyyy');
    store.commit('v2/rentTransaction/initiate/SET_START_TIME', query.time);
    store.commit('v2/rentTransaction/initiate/SET_START_DATE', date);
    store.commit('v2/rentTransaction/initiate/SET_DURATION', query.duration);
    store.commit('v2/rentTransaction/initiate/SET_RENT_PERIOD', query.rpid);
    store.commit('v2/rentTransaction/initiate/SET_LISTING_UUID', query.lid);
    await store.dispatch('global/fetch');
  },
  async mounted() {
    this.$nextTick(() => {
      setTimeout(async () => {
        const resultCheck = await this.$store.dispatch(
          'v2/rentTransaction/initiate/checkRequestBooking',
        );
        if (!resultCheck.valid) {
          await this.$swal(
            this.$t('errors.bookingRequest.initiateTitle'),
            resultCheck.errorMessage,
            'error',
          );
          this.$router.back();
        } else {
          await this.$store.dispatch('v2/rentTransaction/initiate/getInitData');
          if (this.currentPhone) this.localPhone = this.currentPhone;
          await this.getTotalRent();
          this.$store.commit(
            'v2/rentTransaction/initiate/SET_NEW_PRICE',
            this.$store.state.v2.rentTransaction.initiate.pricing.totalRent / this.duration,
          );
        }
      }, 1);
    });
  },
  computed: {
    ...mapState({
      rentPeriodId: state => state.v2.rentTransaction.initiate.rentPeriod,
      startTime: state => state.v2.rentTransaction.initiate.startTime,
      startDate: state => state.v2.rentTransaction.initiate.startDate,
      duration: state => state.v2.rentTransaction.initiate.duration,
      bookingFee: state => state.v2.rentTransaction.initiate.pricing.bookingFee,
      listing: state => state.v2.rentTransaction.initiate.listing,
      listingUuid: state => state.v2.rentTransaction.initiate.listingUuid,
      chosenPromo: state => state.v2.rentTransaction.initiate.promo.chosenPromo,
      promoWarning: state => state.v2.rentTransaction.initiate.promo.warningMessage,
      lamaSewa: state => state.v2.rentTransaction.initiate.pricing.lamaSewa,
      total_rent: state => state.v2.rentTransaction.initiate.pricing.totalRent,
      harga_sewa: state => state.v2.rentTransaction.initiate.pricing.hargaSewa,
      endDateName: state => state.v2.rentTransaction.initiate.pricing.endDateName,
      moveDateName: state => state.v2.rentTransaction.initiate.pricing.moveDateName,
      discount_price: state => state.v2.rentTransaction.initiate.pricing.discountPrice,
      currentPhone: state => (state.global.user ? state.global.user.phone : null),
    }),
    breadcrumbs() {
      if (!this.listing) return [];
      const typeString =
        this.listing.type === 'R' ? this.$t('general.rent') : this.$t('general.sell');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: `${typeString} ${this.listing.property_type_name}`,
          to: `/${this.listing.type === 'R' ? 'rent' : 'sell'}/search?property_type_id=%255B${
            this.listing.property_type_id
          }%255D`,
        },
        {
          text: this.listing.listing_title,
          to: this.listing.web_url,
        },
        {
          text: this.$t('general.requestToBook'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
    loading: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.loading;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_LOADING', val);
      },
    },
    localPhone: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.localPhone;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_LOCAL_PHONE', val);
      },
    },
    localPhoneCountryCode: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.localPhoneCountryCode;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_LOCAL_PHONE_COUNTRY_CODE', val);
      },
    },
  },
  watch: {
    chosenPromo() {
      this.getTotalRent();
    },
  },
  methods: {
    timeLabel(listingOperational) {
      return listingOperational + '.00';
    },
    async getTotalRent() {
      let moveTime = null;
      if (this.rentPeriodId == 5) {
        moveTime = this.timeLabel(this.startTime);
      }
      let payload = {
        periode_id: this.rentPeriodId,
        move_date: this.$date.format(this.startDate, 'yyyy-MM-dd'),
        move_time: moveTime,
        duration: this.duration,
        uuid: this.listingUuid,
      };
      if (!this.promoWarning) {
        payload.promo_uuid = this.chosenPromo?.uuid;
      }
      await this.$store.dispatch('v2/rentTransaction/initiate/pricing/getTotalRent', payload);
    },
    async validate() {
      return (
        (await this.$refs.userDataSection.$validate()) && (await this.$refs.negoSection.$validate())
      );
    },
    async submit() {
      try {
        this.loading = true;
        const isValid = await this.validate();
        if (isValid) {
          this.$store.commit('v2/rentTransaction/initiate/SET_PHONE', this.localPhone);
          this.$store.commit(
            'v2/rentTransaction/initiate/SET_PHONE_COUNTRY_CODE',
            this.localPhoneCountryCode,
          );
          const result = await this.checkUnpaidBooking();
          const allowBook = result.is_allowed;
          const warningBook = result.warning_message;
          console.log(allowBook);
          if (allowBook === true) {
            const response = await this.$store.dispatch(
              'v2/rentTransaction/initiate/submit',
              this.listing.uuid,
            );
            if (response) {
              this.$router.push({
                path: '/mybooking/detail',
                query: {
                  type: 'RENT_TRANSACTION',
                  uuid: response,
                },
              });
              this.customAgreement = {};
            }
          } else {
            this.$swal(this.$t('booking.swal.paymentExist.title'), warningBook, 'error');
            this.$swal({
              title: this.$i18n.t('booking.swal.paymentExist.title'),
              text: warningBook,
              type: 'error',
              confirmButtonText: this.$t('booking.swal.paymentExist.checkBtn'),
            }).then(async result => {
              if (result.value) {
                this.$router.push('/dashboard/mybooking');
              }
            });
          }
        }
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loading = false;
      }
    },
    async checkUnpaidBooking() {
      try {
        this.loading = true;
        let checkBooking = await this.$store.dispatch(
          'v2/rentTransaction/initiate/isBookingAllowed',
          this.listing.uuid,
        );
        return checkBooking;
      } catch (e) {
        return false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
