<template>
  <div v-if="listing">
    <router-link v-if="listing.web_url" :to="listing.web_url" style="text-decoration: none;">
      <div class="card--booking">
        <div class="left--part">
          <img :src="listing.primary_photo" :alt="listingTitle" />
        </div>
        <div class="right--part">
          <div class="top--part">
            <div class="title--text">
              {{ listingTitle }}
            </div>
          </div>
          <div class="bottom--part">
            <div class="label--container">
              <div class="label mr-2">
                {{ $t(`general.${listing.type}`) }}
              </div>
              <div class="label">{{ listing.property_type_name }}</div>
            </div>
          </div>
          <div class="bottom--part" v-if="listing.type === 'S'">
            <div v-if="listing.done_year_est">
              <strong
                >{{ $t('addListing.doneEstimation') }}:
                <span class="color--primary">{{ listing.done_year_est }}</span></strong
              >
            </div>
            <div v-else>
              <strong
                >{{ $t('booking.status') }}:
                <span class="color--primary">{{ $t('booking.ready') }}</span></strong
              >
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <a v-else href="javascript:;" style="text-decoration: none;">
      <div class="card--booking">
        <div class="left--part">
          <img :src="listing.primary_photo" :alt="listingTitle" />
        </div>
        <div class="right--part">
          <div class="top--part">
            <div class="title--text">
              {{ listingTitle }}
            </div>
          </div>
          <div class="bottom--part">
            <div class="label--container">
              <div class="label mr-2">
                {{ $t(`general.${listing.type}`) }}
              </div>
              <div class="label">{{ listing.property_type_name }}</div>
            </div>
          </div>
          <div class="bottom--part" v-if="listing.type === 'S'">
            <div v-if="listing.done_year_est">
              <strong
                >{{ $t('addListing.doneEstimation') }}:
                <span class="color--primary">{{ listing.done_year_est }}</span></strong
              >
            </div>
            <div v-else>
              <strong
                >{{ $t('booking.status') }}:
                <span class="color--primary">{{ $t('booking.ready') }}</span></strong
              >
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  props: {
    listing: {
      require: true,
    },
  },
  computed: {
    ...mapState({
      listingType: state => state.v2.booking.listingType,
    }),
    listingTitle() {
      return this.listing.listing_title ? this.listing.listing_title : this.listing.title;
    },
  },
};
</script>
